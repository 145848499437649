/* start New  */
.Loader {
  position: fixed;
  /* width: 500p; */
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff6b;
  z-index: 999999;
}

.spinner {
  width: 11.2px;
  height: 11.2px;
  animation: spinner-o824ag 1s infinite linear;
}

.spinner div {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #3e4452;
  border-radius: 50%;
  animation: spinner-vse6n7 1.25s infinite ease;
}

.spinner div:nth-child(1) {
  --rotation: 90;
}

.spinner div:nth-child(2) {
  --rotation: 180;
}

.spinner div:nth-child(3) {
  --rotation: 270;
}

.spinner div:nth-child(4) {
  --rotation: 360;
}

@keyframes spinner-vse6n7 {
  0%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}

@keyframes spinner-o824ag {
  to {
    transform: rotate(360deg);
  }
}

/* start Laoding Code */
.mainLoader {
  background-color: white;
}

.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 20px auto;
  box-sizing: border-box;
  position: relative;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  left: 0;
  bottom: 0;
  position: absolute;
  border-radius: 50% 50% 0;
  border: 15px solid #3e4452;
  transform: rotate(45deg) translate(0, 0);
  animation: animMarker 0.4s ease-in-out infinite alternate;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150%;
  width: 24px;
  height: 4px;
  border-radius: 50%;
  background: #3e4452;
  animation: animShadow 0.4s ease-in-out infinite alternate;
  -webkit-animation: animShadow 0.4s ease-in-out infinite alternate;
}

@keyframes animMarker {
  0% {
    transform: rotate(45deg) translate(5px, 5px);
  }

  100% {
    transform: rotate(45deg) translate(-5px, -5px);
  }
}

@keyframes animShadow {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

:root {
  --main_color: #d1a753;
  /* --hover:  #5f63f2; */
  /* --main-color-2: #f69322; */
  --main-color-2: #1e3a6c;
  --border_color: #1e3a6c;
  --bg: #f4f5f7;
  --bg2: #f0f7fa;
  --white: #fff;
  --box1: 0 0 30px rgba(146, 153, 184, 0.1);
  --box2: 0 5px 20px rgba(146, 153, 184, 0.03);
  --text_color: #8c98a9;
  --mes: #e9e9ff;
  --shadow: 0 16px 32px 0 rgba(112, 126, 147, 0.12);
  --bg-color: #f8f8f8;
  --bs-link-color: #0d6efd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--bg);
  /* font-family: 'Cairo', sans-serif; */
}

:is(h1, h2, h3, h4, h5, h6, p, a, button, label) {
  font-family: Droid Arabic Naskh, sans-serif;
  /* font-family: "DIN Next Medium", sans-serif; */
}
:is(h1, h2, h3, h4, h5, h6) {
  font-weight: 700 !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 575.98px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 767.98px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 991.98px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1199.98px) {
  .container {
    width: 1140px;
  }
}

@media (min-width: 1399.98px) {
  .container {
    width: 1320px;
  }
}

.main-title {
  color: var(--border_color);
  margin: 10px auto 10px;
  font-weight: bold;
  position: relative;
  border: 2px solid var(--border_color);
  width: fit-content;
  padding: 10px 20px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  text-transform: capitalize;
  cursor: default;
}

.main-title::after,
.main-title::before {
  content: "";
  position: absolute;
  background-color: var(--border_color);
  width: 12px;
  height: 12px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  z-index: 1;
}

.main-title::after {
  right: -30px;
}

.main-title::before {
  left: -30px;
}

.main-two {
  /* text-transform: uppercase; */
  color: black;
  padding: 15px;
  position: relative;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: clamp(18px, 2vw, 25px);
}

.main-two::after {
  position: absolute;
  content: "";
  bottom: 5px;
  left: 50%;
  width: 100px;
  transform: translateX(-50%);
  height: 3px;
  background-color: var(--border_color);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.p-inputnumber {
  width: 100% !important;
}

.p-checkbox {
  margin-left: 10px;
}

.p-float-label {
  width: 100%;
}

.map-container {
  width: 100%;
  height: 300px;
}

.addBTN {
  border: none;
  min-width: 50px;
  padding: 10px 15px;
  background-color: var(--main_color);
  color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: clamp(15px, 2vw, 20px);
  cursor: pointer;
  margin: 10px auto;
}

.sliderInfo {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.MainSlider button {
  border: none;
  color: white;
  background-color: #c4302b;
  padding: 10px 15px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
}

.SliderImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.MainSlider {
  background-color: white;
  padding: 10px;
  box-shadow: var(--box1);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-height: 400px;
  max-height: 500px;
  overflow-y: scroll;
}

.ChickListScroll {
  width: 100%;
}

.CheckSpafic {
  margin: 10px 0 !important;
}

.ImageShow {
  position: relative;

  margin: 0 auto;
}

.cramp {
  font-size: 22px;
  align-items: center;
  margin-top: 10px;
}
td,
th {
  text-align: center !important;
  justify-content: center !important;
  /* cursor: pointer !important; */
}

.p-column-header-content {
  justify-content: center;
}

.p-dropdown {
  width: 100% !important;
}

.menu_Add_Btn {
  color: white;
  background-color: var(--border_color);
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.menu_Add_Btn:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.Delete_from_Menu {
  background-color: #c4302b;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: #303441 !important;
  color: #303441 !important;
  border-color: #303441 !important;
}

.Btn_Active {
  background-color: var(--main_color) !important;
}

.Social_div {
  width: 100%;
}

.Icons_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.Icons_container svg {
  margin-right: 10px;
  font-size: 22px;
}
.Input_header {
  margin: 0 auto;
  width: fit-content;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding: 5px;
}

.Team_Section {
  /* margin-top: 10px; */
  margin-bottom: 10px;
  background-color: #ececec;
  position: relative;
  direction: rtl;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.Team_Section span {
  position: absolute;
  right: -5px;
  left: auto;
  top: 0;
  z-index: 1;
  width: 50px;
  height: 100%;
  background-color: #d1a753;
}
.Team_Section h2 {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin: 0;
  min-width: 159px;
  line-height: 29px;
  padding: 7px 10px 12px;
  font-family: "DIN Next Bold", sans-serif !important;
  display: block;
}
.Team_Section button {
  background-color: #d1a753 !important;
}
.Rows_section button {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin: 0;
  min-width: 159px;
  line-height: 29px;
  padding: 7px 10px 12px;
  font-family: "DIN Next Bold", sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e3a6c;
  border: none;
  cursor: pointer;
  direction: rtl;
}
.Rows_section button svg {
  font-size: 20px;
  margin-left: 5px;
}
.Team_title {
  text-decoration: none;
  text-align: right;
  display: block;
  background-color: #1e3a6c;
  width: fit-content;
  position: relative;
  z-index: 2;
  margin-right: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.Team_title:hover {
  color: var(--main-color-2);
}

.Row_section {
  padding: 15px 15px 10px;
  min-height: 200px;
  width: 100%;
  border: 1px solid black;
}
.Member_section {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Member_section img {
  width: 100%;
  height: 150px;
  background-color: white;
  /* height: 100%; */
}
.Member_info {
  text-align: center;
}

.Rows_section .update_depart {
  background-color: #9d9d9c !important;
}
.Rows_section .delete_depart {
  background-color: #c4302b !important ;
}

.Media_Section {
  background-color: white;
  padding: 20px;
  min-height: 100px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.AddLink {
  background-color: white;
  padding: 20px;
  min-height: 100px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.Media_Section .Delete_button button {
  background-color: #c4302b !important ;
}

.background_Section {
  background-color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 10px;
}

.status h2 span {
  text-transform: capitalize;
  color: #1e3a6c;
}

/* .progress_count {
  flex: 0.1;
  text-align: center;
  background: var(--main-color-2);
  color: white;
  border-radius: 6px;
  margin: 0 5px;
  font-weight: bold;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
} */
.progress_count {
  flex: 0.1;
  text-align: center;
  background: var(--main_color);
  color: white;
  border-radius: 6px;
  margin: 0 5px;
  font-weight: bold;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.p-progressbar .p-progressbar-value {
  background: var(--main-color) !important;
}

.p-progressbar {
  flex: auto !important;
}
.poll_Progress {
  flex: auto !important;
}
.Image_Login {
  width: 70%;
  margin: 0 auto;
  height: 150px;
  position: relative;
}
.Image_Login img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.EmptyImage {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.EmptyImage .Image_container {
  width: 80%;
  margin: 0 auto;
  height: 400px;
}
.EmptyImage .Image_container img {
  width: 100%;
  height: 100%;
}
.EmptyImage h2 {
  color: var(--main_color);
}
.shadow_Role {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ffffff6b;
}

.RightDir {
  direction: rtl;
}

.LeftDir {
  direction: ltr;
}

.MainImage_cont {
  position: relative;
  width: 100%;
}
.MainImage_cont img {
  width: 100%;
  height: 100%;
}
.article_body img {
  max-width: 80%;
  display: flex;
  margin: 0 auto;
}
/* .AddLink {
} */

.article_body .wp-block-spacer {
  height: 5px !important;
}

.article_body iframe {
  width: 900px;
  height: 450px;
  margin: 0 auto;
  display: flex;
  text-align: center;
}
.article_body .wp-block-spacer {
  height: 5px !important;
}

.article_body p > br:only-child {
  /* display: none; */
  content: "";
  margin: 15px;
  display: block;
  font-size: 24%;
  /* margin-bottom: 5px;
  line-height: 31px !important; */
}
@media (max-width: 1199.98px) {
  .article_body iframe {
    width: 100%;
    height: 300px;
  }
  .article_body_en p {
    font-size: 14px;
    line-height: 26px;
    /* font-family: "DIN Next Bold", sans-serif !important; */
  }
}
.article_body img {
  max-width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  margin: 0 auto;
}

.article_body {
  text-align: justify;
}
.article_body figcaption {
  text-align: center !important;
  margin-top: 10px;
}
.article_body p {
  font-size: 16px;
  line-height: 31px;
  text-align: justify !important;
  margin-bottom: 15px !important;
}
/* .article_body_en p {
   font-family: "DIN Next Bold", sans-serif !important;
 } */
.article_body a {
  text-decoration: none;
  /* color: inherit; */
}

/* Ricttext  */

:root .has-pale-pink-background-color {
  background-color: #f78da7;
}

:root .has-vivid-red-background-color {
  background-color: #cf2e2e;
}

:root .has-luminous-vivid-orange-background-color {
  background-color: #ff6900;
}

:root .has-luminous-vivid-amber-background-color {
  background-color: #fcb900;
}

:root .has-light-green-cyan-background-color {
  background-color: #7bdcb5;
}

:root .has-vivid-green-cyan-background-color {
  background-color: #00d084;
}

:root .has-pale-cyan-blue-background-color {
  background-color: #8ed1fc;
}

:root .has-vivid-cyan-blue-background-color {
  background-color: #0693e3;
}

:root .has-vivid-purple-background-color {
  background-color: #9b51e0;
}

:root .has-white-background-color {
  background-color: #fff;
}

:root .has-very-light-gray-background-color {
  background-color: #eee;
}

:root .has-cyan-bluish-gray-background-color {
  background-color: #abb8c3;
}

:root .has-very-dark-gray-background-color {
  background-color: #313131;
}

:root .has-black-background-color {
  background-color: #000;
}

:root .has-pale-pink-color {
  color: #f78da7;
}

:root .has-vivid-red-color {
  color: #cf2e2e;
}

:root .has-luminous-vivid-orange-color {
  color: #ff6900;
}

:root .has-luminous-vivid-amber-color {
  color: #fcb900;
}

:root .has-light-green-cyan-color {
  color: #7bdcb5;
}

:root .has-vivid-green-cyan-color {
  color: #00d084;
}

:root .has-pale-cyan-blue-color {
  color: #8ed1fc;
}

:root .has-vivid-cyan-blue-color {
  color: #0693e3;
}

:root .has-vivid-purple-color {
  color: #9b51e0;
}

:root .has-white-color {
  color: #fff;
}

:root .has-very-light-gray-color {
  color: #eee;
}

:root .has-cyan-bluish-gray-color {
  color: #abb8c3;
}

:root .has-very-dark-gray-color {
  color: #313131;
}

:root .has-black-color {
  color: #000;
}

:root .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: linear-gradient(-135deg, #0693e3, #9b51e0);
}

:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: linear-gradient(-135deg, #00d084, #0693e3);
}

:root .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: linear-gradient(-135deg, #7adcb4, #00d082);
}

:root .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: linear-gradient(-135deg, #fcb900, #ff6900);
}

:root .has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: linear-gradient(-135deg, #ff6900, #cf2e2e);
}

:root .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: linear-gradient(-135deg, #eee, #a9b8c3);
}

:root .has-cool-to-warm-spectrum-gradient-background {
  background: linear-gradient(
    -135deg,
    #4aeadc,
    #9778d1 20%,
    #cf2aba 40%,
    #ee2c82 60%,
    #fb6962 80%,
    #fef84c
  );
}

:root .has-blush-light-purple-gradient-background {
  background: linear-gradient(-135deg, #ffceec, #9896f0);
}

:root .has-blush-bordeaux-gradient-background {
  background: linear-gradient(-135deg, #fecda5, #fe2d2d 50%, #6b003e);
}

:root .has-purple-crush-gradient-background {
  background: linear-gradient(-135deg, #34e2e4, #4721fb 50%, #ab1dfe);
}

:root .has-luminous-dusk-gradient-background {
  background: linear-gradient(-135deg, #ffcb70, #c751c0 50%, #4158d0);
}

:root .has-hazy-dawn-gradient-background {
  background: linear-gradient(-135deg, #faaca8, #dad0ec);
}

:root .has-pale-ocean-gradient-background {
  background: linear-gradient(-135deg, #fff5cb, #b6e3d4 50%, #33a7b5);
}

:root .has-electric-grass-gradient-background {
  background: linear-gradient(-135deg, #caf880, #71ce7e);
}

:root .has-subdued-olive-gradient-background {
  background: linear-gradient(-135deg, #fafae1, #67a671);
}

:root .has-atomic-cream-gradient-background {
  background: linear-gradient(-135deg, #fdd79a, #004a59);
}

:root .has-nightshade-gradient-background {
  background: linear-gradient(-135deg, #330968, #31cdcf);
}

:root .has-midnight-gradient-background {
  background: linear-gradient(-135deg, #020381, #2874fc);
}

:root .has-link-color a {
  color: #00e;
  color: var(--wp--style--color--link, #00e);
}
.has-small-font-size {
  font-size: 0.8125em;
}

.has-normal-font-size,
.has-regular-font-size {
  font-size: 1em;
}

.has-medium-font-size {
  font-size: 1.25em;
}

.has-large-font-size {
  font-size: 2.25em;
}

.has-huge-font-size,
.has-larger-font-size {
  font-size: 2.625em;
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-left {
  text-align: left;
}

.has-text-align-right {
  text-align: right;
}

#end-resizable-editor-section {
  display: none;
}

.aligncenter {
  clear: both;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 22, 2023 */

/* @font-face {
    font-family: 'droid_arabic_naskhbold';
    src: url('arbfonts-droid_arabic_naskh_bold-webfont.woff2') format('woff2'),
         url('arbfonts-droid_arabic_naskh_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'droid_arabic_naskhregular';
    src: url('arbfonts-droid_arabic_naskh-webfont.woff2') format('woff2'),
         url('arbfonts-droid_arabic_naskh-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'droid_arabic_naskhbold';
    src: url('arbfonts-droid.arabic.naskh_.bold_downloadsoftware.ir_-webfont.woff2') format('woff2'),
         url('arbfonts-droid.arabic.naskh_.bold_downloadsoftware.ir_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'droid_arabic_naskhregular';
    src: url('arbfonts-droid.arabic.naskh_.regular_downloadsoftware.ir_-webfont.woff2') format('woff2'),
         url('arbfonts-droid.arabic.naskh_.regular_downloadsoftware.ir_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'droid_arabic_naskhbold';
    src: url('arbfonts-droidnaskh-bold-webfont.woff2') format('woff2'),
         url('arbfonts-droidnaskh-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'droid_arabic_naskhregular';
    src: url('arbfonts-droid-naskh-regular-webfont.woff2') format('woff2'),
         url('arbfonts-droid-naskh-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

} */

@font-face {
  font-family: "Droid Arabic Naskh Bold";
  src: local("Droid Arabic Naskh Bold"), local("DroidArabicNaskh-Bold"),
    url("DroidArabicNaskh-Bold.woff2") format("woff2"),
    url("DroidArabicNaskh-Bold.woff2") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Droid Arabic Naskh";
  src: local("Droid Arabic Naskh"), local("DroidArabicNaskh"),
    url("DroidArabicNaskh.woff2") format("woff2"),
    url("DroidArabicNaskh.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DIN Next Bold";
  src: local("DIN Next LT Arabic Bold"), local("DINNextLTArabic-Bold"),
    url("DINNextLTArabic-Bold.woff2") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "DIN Next Medium";
  src: local("DIN Next LT Arabic Medium"), local("DINNextLTArabic-Medium"),
    url("DINNextLTArabic-Medium.woff2") format("woff2"),
    url("DroidArabicNaskh.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
}
